<template>
  <div class="scroll">
    <div class="item">
      <div class="itemLeft"><span>公司</span><span class="red">*</span></div>
      <div class="itemRight">
        <span>{{ this.comname }}</span>
      </div>
    </div>
    <div class="item">
      <div class="itemLeft"><span>姓名</span><span class="red">*</span></div>
      <div class="itemRight">
        <input v-model="form.cardempname"
               type="text"
               placeholder="请输入姓名" />
      </div>
    </div>
    <div class="item">
      <div class="itemLeft"><span>性别</span><span class="red">*</span></div>
      <div class="itemRight">
        <input v-model="showSex"
               readonly
               @click="changeSex"
               type="text"
               placeholder="请选择性别" />
        <md-selector v-model="isSelectorShow"
                     :data="sexlist"
                     :default-value="form.cardsex"
                     max-height="320px"
                     @choose="onSelectorChoose"></md-selector>
      </div>
    </div>
    <div class="item">
      <div class="itemLeft"><span>手机号</span><span class="red">*</span></div>
      <div class="itemRight">
        <input v-model="form.cardmobile"
               type="text"
               maxlength="11"
               oninput="value=value.replace(/[^\d]/g,'')"
               placeholder="请输入手机号" />
      </div>
    </div>
    <div class="item">
      <div class="itemLeft">
        <span>邮箱</span>
      </div>
      <div class="itemRight">
        <input v-model="form.email"
               type="text"
               placeholder="请输入邮箱" />
      </div>
    </div>
    <div class="item">
      <div class="itemLeft">
        <span>微信号</span>
      </div>
      <div class="itemRight">
        <input v-model="form.wxnumber"
               type="text"
               placeholder="请输入微信号" />
      </div>
    </div>
    <div class="item">
      <div class="itemLeft">
        <span>职位</span>
      </div>
      <div class="itemRight">
        <input v-model="form.carddegreeno"
               type="text"
               placeholder="请输入职位" />
      </div>
    </div>
    <div class="item">
      <div class="itemLeft">
        <span>地址</span>
      </div>
      <div class="itemRight">
        <input v-model="form.workarea"
               type="text"
               maxlength="12"
               placeholder="请输入地址" />
      </div>
    </div>
    <div class="brief">
      <div class="itemLeft">
        <span>我的个人简介</span>
      </div>
      <div class="itemRight brieftext">
        <textarea v-model="form.pdesc"></textarea>
      </div>
      <div class="write"
           @click="writeBrief"
           :style="`color:${colorprimary}`">
        <span>不会写简介？戳这里></span>
      </div>
    </div>
    <div class="brief">
      <div class="itemLeft">
        <span>名片头像</span>
      </div>
      <div class="itemRight brieftext">
        <div class="myPhoto">
          <ul class="clearfix">
            <li v-if="form.headimg">
              <img class="img"
                   :src="form.headimg"
                   alt="" />
            </li>
            <li>
              <div class="header">
                <simple-cropper :initParam="uploadParam"
                                :successCallback="uploadHandle"
                                ref="cropper"
                                class="box">
                  <img class="img cursor"
                       src="@/assets/abt/img/changetx.png"
                       @click="upload('cropper')" />
                </simple-cropper>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="brief">
      <div class="itemLeft">
        <span>名片样式</span>
      </div>
      <div class="itemRight brieftext">
        <div class="cardType">
          <div class="top">
            <div class="selectcard"
                 v-for="(item, index) in carddemo"
                 :key="index">
              <img :src="item.cardbg"
                   alt=""
                   @click="selectcard(item.cardstyle)"
                   class="cardimg" />
              <img src="@/assets/abt/img/select.png"
                   alt=""
                   class="select"
                   v-if="item.cardstyle == form.cardstyle" />
            </div>
          </div>
          <div class="bgImg"
               v-if="form.cardstyle == '1'">
            <div class="cardbg">
              <img class="cardbg1"
                   src="@/assets/abt/img/card01bg.png"
                   alt="" />
              <img class="logo1"
                   :src="mplogo"
                   alt="" />
              <div class="cardbg2">
                <p>{{ form.cardempname }}</p>
                <p class="carddegre">{{ form.carddegreeno }}</p>
              </div>
              <div class="cardbg3"
                   v-show="form.cardmobile != ''">
                <md-icon name="phone"
                         color="white"
                         size="lg"></md-icon>
                <span> {{ form.cardmobile }}</span>
              </div>
              <div class="cardbg4"
                   v-show="form.email != ''">
                <md-icon name="id-card"
                         color="white"
                         size="lg"></md-icon>
                <span> {{ form.email }}</span>
              </div>
              <div class="cardbg5"
                   v-show="form.workarea != ''">
                <md-icon name="location"
                         color="white"
                         size="lg"></md-icon>
                <span> {{ form.workarea }}</span>
              </div>
              <div class="cardbg6">
                <img class="img"
                     :src="form.headimg"
                     alt="" />
              </div>
              <div class="cardbg7">
                <img class="cardImg"
                     :src="ossurl + form.wxewmurl"
                     alt="" />
              </div>
            </div>
          </div>
          <div class="bgImg"
               v-if="form.cardstyle == '2'">
            <div class="cardbg">
              <img class="cardbg1"
                   src="@/assets/abt/img/card2bg.png"
                   alt="" />
              <img class="logo2"
                   :src="mplogo"
                   alt="" />
              <div class="cardbg2_1">
                <p>{{ form.cardempname }}</p>
                <p class="carddegre">{{ form.carddegreeno }}</p>
              </div>
              <div class="cardbg3_1"
                   v-show="form.cardmobile != ''">
                <md-icon name="phone"
                         color="#0066b3"
                         size="lg"></md-icon>
                <span> {{ form.cardmobile }}</span>
              </div>
              <div class="cardbg4_1"
                   v-show="form.email != ''">
                <md-icon name="id-card"
                         color="#0066b3"
                         size="lg"></md-icon>
                <span> {{ form.email }}</span>
              </div>
              <div class="cardbg5_1"
                   v-show="form.workarea != ''">
                <md-icon name="location"
                         color="#0066b3"
                         size="lg"></md-icon>
                <span> {{ form.workarea }}</span>
              </div>
              <div class="cardbg6_1">
                <img class="img"
                     :src="form.headimg"
                     alt="" />
              </div>
              <div class="cardbg7_1">
                <img class="cardImg"
                     :src="ossurl + form.wxewmurl"
                     alt="" />
              </div>
            </div>
          </div>
          <div class="bgImg"
               v-if="form.cardstyle == '3'">
            <div class="cardbg">
              <img class="cardbg1"
                   src="@/assets/abt/img/card3bg.png"
                   alt="" />
              <img class="logo3"
                   :src="mplogo"
                   alt="" />
              <div class="cardbg2_2">
                <p>{{ form.cardempname }}</p>
                <p class="carddegre">{{ form.carddegreeno }}</p>
              </div>
              <div class="cardbg3_2"
                   v-show="form.cardmobile != ''">
                <md-icon name="phone"
                         color="rgb(217, 212, 157)"
                         size="lg"></md-icon>
                <span> {{ form.cardmobile }}</span>
              </div>
              <div class="cardbg4_2"
                   v-show="form.email != ''">
                <md-icon name="id-card"
                         color="rgb(217, 212, 157)"
                         size="lg"></md-icon>
                <span> {{ form.email }}</span>
              </div>
              <div class="cardbg5_2"
                   v-show="form.workarea != ''">
                <md-icon name="location"
                         color="rgb(217, 212, 157)"
                         size="lg"></md-icon>
                <span> {{ form.workarea }}</span>
              </div>
              <div class="cardbg6_2">
                <img class="img"
                     :src="form.headimg"
                     alt="" />
              </div>
              <div class="cardbg7_2">
                <img class="cardImg"
                     :src="ossurl + form.wxewmurl"
                     alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="brief">
      <div class="itemLeft">
        <span>我的照片</span>
        <span class="number">{{ zplen }}/9</span>
      </div>
      <div class="itemRight brieftext">
        <div class="myPhoto">
          <ul class="clearfix">
            <draggable v-model="form.photo"
                       @update="datadragEnd"
                       :options="{ animation: 500 }">
              <li v-for="(item, index) in form.photo"
                  :key="index"
                  :value="item">
                <img :src="ossurl + item"
                     class="img"
                     alt="" />
                <div class="del_img"
                     @touchstart="delMyphoto(index)"></div>
              </li>
              <!-- <li v-for="(item,index) in form.photo" :key="index" :value="item">
                <img :src="ossurl+item" class="img" alt="" />
                <div class="del_img" @click="delMyphoto(index)"></div>
              </li> -->
            </draggable>
            <li v-if="zplen >= 0 && zplen < 9">
              <img class="img"
                   src="@/assets/abt/img/add.png"
                   @click="uploadImg" />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="btn">
      <div @click="save"
           class="cardBtn"
           :style="`backgroundColor:${colorprimary}`">保 存</div>
      <!-- <md-button type="primary" @click="save" class="cardBtn">保 存</md-button> -->
    </div>
    <div class="back_box"
         @click="back"
           :style="`backgroundColor:${colorprimary}`">
      <img src="@/assets/abt/img/fh.png"
           alt=""
           class="fh_img" />
      <div class="fh_title">返回</div>
    </div>
    <md-selector v-model="isShowSelect"
                 title="个人简介"
                 :data="datalist"
                 okText="确认"
                 cancelText="取消"
                 max-height="400px"
                 is-check
                 @confirm="onSelectorConfirm"></md-selector>
  </div>
</template>
<script>
import {
  selectUserCard,
  updateCard,
  selectMJ
} from '@/api/abt/customerOperation/visitingcard'
import { Uploadpic } from '@/api/abt/customerOperation/common'
import { getConfig, wxpicUpload } from '@/lib/wxapi'
import config from '@/config'
import { getStorage } from '@/lib/util'
import { Toast } from 'mand-mobile'
import simpleCropper from '../component/simpleCropper'
// import { type } from 'os'
import draggable from 'vuedraggable'
export default {
  components: {
    draggable,
    simpleCropper
  },
  data () {
    return {
      user: '',
      ossurl: '',
      comname: '',
      txImg: '',
      zplen: 0,
      uploadParam: {
        file: 'mptx',
        type: 'mptx',
        fileType: 'recruit', // 其他上传参数
        uploadURL: '/empno/getUpload', // 上传地址
        scale: 4, // 相对手机屏幕放大的倍数: 4倍
        ratio: 1 / 1
        // userid: localStorage.getItem(window.g.beforestr + '_userid'),
        // comid: localStorage.comid
      }, // 上传头像初始化数据
      // checked: '0',
      carddemo: [
        {
          cardstyle: '1',
          cardbg: require('@/assets/abt/img/ys02.png'),
          // cardstyle: 'card01',
          checked: true
        },
        {
          cardstyle: '2',
          cardbg: require('@/assets/abt/img/ys3.png'),
          // cardstyle: 'card02',
          checked: false
        },
        {
          cardstyle: '3',
          cardbg: require('@/assets/abt/img/ys1.png'),
          // cardstyle: 'card03',
          checked: false
        }
      ],
      sexlist: [
        {
          code: '2',
          name: '男'
        },
        {
          code: '1',
          name: '女'
        },
        {
          code: '0',
          name: '未知性别'
        },
        {
          code: '9',
          name: '未说明性别'
        }
      ],
      isSelectorShow: false,
      showSex: '',
      photosave: [],
      form: {
        cardempname: '',
        cardsex: '',
        cardmobile: '',
        wxnumber: '',
        workarea: '',
        pdesc: '',
        carddegreeno: '',
        email: '',
        cardstyle: '1',
        headimg: '',
        photo: [],
        wxewmurl: ''
      },
      isShowSelect: false,
      datalist: [
        {
          value: '1',
          text:
            '我选择保险行业的初衷，源于责任与爱。敬业和真诚是我的名片，我将以百分百的真诚对待每一个客户，为您带来最贴心最专业的服务！'
        },
        {
          value: '2',
          text:
            '在这里，肯努力就能发光。短短的时间，我从刚入行业的小白，成功晋升主管，带领团队学习、成长、成功，为无数家庭提供一站式的专业服务。如果您有任何保险问题或从业意向，欢迎识别上面的二维码，加我微信随时联系。'
        },
        {
          value: '3',
          text:
            '从事保险行业多年，历经多次客户理赔，我越来越认识到自己背后的那份责任。如果您选择信任我，我将以绝对的专业和真诚，守护您和家人的美好未来！'
        },
        {
          value: '4',
          text:
            '保险不仅是一份职业，更是我人生的广阔舞台。在公司的这些年，我收获了很多荣誉和认可。这些，都是新老客户对我专业的肯定。但收获更多的是坚定从事保险的信心，我要帮助更多人拿走担忧，获得安全感。'
        },
        {
          value: '5',
          text:
            '您好！作为一个保险顾问，我很高兴可以为您提供专业的保险咨询和产品推荐，并且真诚地希望接下来的日子您可以在我的帮助下，配置到适合您家庭结构和需求的保险。当然，其他问题您也可以和我沟通，我愿意成为您的朋友，帮您分忧解惑。'
        }
      ],
      mplogo: '',
      colorprimary: ''
    }
  },
  created () {
    this.colorprimary = getStorage('theme', '')
    this.user = getStorage('u_s', {})
    this.ossurl = getStorage('ossurl', '')
    this.comname = getStorage('comname', '')
    let logodata = getStorage('sys_info', {})
    this.mplogo = logodata.mplogo ? logodata.mplogo : ''
    this.height = document.body.clientHeight
    this.getSex()
    this.getData()
  },
  mounted () {
    document.querySelector('.scroll').style.height = `${this.height}px`
    $(document).on('focusout', function () {
      setTimeout(() => {
        // 软键盘收起的事件处理
        window.scroll(0, 0)
      }, 300)
    })
    let path = ''
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      // ios
      if (this.$route.query.frompage == 'source') {
        path = config.redirect_uri + '/source'
      } else {
        path = config.redirect_uri
      }
    } else {
      // android
      path = window.location.href
    }
    getConfig(path)
  },
  methods: {
    changeSex () {
      this.isSelectorShow = true
    },
    onSelectorChoose ({ name, value }) {
      this.form.cardsex = value
      this.showSex = name
    },
    back () {
      this.$router.push('/visitingcard')
    },
    getData () {
      let data = {
        empno: this.user.empno,
        flag: '1',
        type: this.user.rytype
      }
      selectUserCard(data).then(res => {
        this.form = res.data.data
        if (!this.form.cardstyle) {
          this.form.cardstyle = '1'
        }
        if (this.form.pdesc == '') {
          this.form.pdesc = this.datalist[0].text
        }
        if (this.form.photo) {
          this.zplen = this.form.photo.length
        } else {
          this.zplen = 0
        }
        this.sexlist.forEach((item, index) => {
          if (this.form.cardsex == item.code) {
            this.showSex = item.name
          }
        })
      })
    },
    selectcard (cardstyle) {
      for (var i = 0; i < this.carddemo.length; i++) {
        this.carddemo[i].checked = false
        if (this.carddemo[i].cardstyle === cardstyle) {
          this.carddemo[i].checked = true
          this.form.cardstyle = cardstyle
        }
      }
    },
    getSex () {
      selectMJ({ list: ['SEX'] }).then(res => {
        this.sexlist = res.data.data[0].value
        this.sexlist.forEach((item, index) => {
          this.$set(this.sexlist[index], 'value', item.code)
          this.$set(this.sexlist[index], 'text', item.name)
        })
      })
    },
    // 上传头像
    upload (ele) {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        console.log(this.$refs[ele].upload())
      }
      this.$refs[ele].upload()
    },
    // 上传头像成功回调
    uploadHandle (data) {
      this.form.headimg = data.url
      Toast.succeed('上传成功')
    },
    delMyphoto (index) {
      this.form.photo.splice(index, 1)
      // this.photosave.splice(index, 1)
      this.zplen -= 1
    },
    uploadImg () {
      if (this.form.photo) {
        let len = 9 - this.form.photo.length
        let resserver = wxpicUpload(len)
        resserver.then(res => {
          let num = res.length
          if (num <= len) {
            for (let i = 0; i < num; i++) {
              this.form.photo.push(res[i])
            }
            this.zplen += num
          } else {
            Toast.info('最多上传9张照片')
            for (let i = 0; i < len; i++) {
              this.form.photo.push(res[i])
            }
            this.zplen = 9
          }
          Toast.succeed('上传成功')
        })
      } else {
        let len = 9
        let resserver = wxpicUpload(len)
        resserver.then(res => {
          this.form.photo = res
          this.zplen = this.form.photo.length
          Toast.succeed('上传成功')
        })
      }
    },
    writeBrief () {
      this.isShowSelect = true
    },
    onSelectorConfirm ({ text }) {
      this.form.pdesc = text
    },
    // 拖拽事件结束
    datadragEnd (evt) {
      evt.preventDefault()
    },
    save () {
      if (this.form.cardempname === '') {
        Toast.info('请填写姓名')
        return
      }
      if (this.form.cardsex === '') {
        Toast.info('请填写性别')
        return
      }
      if (this.form.cardmobile === '') {
        Toast.info('请填写手机号')
        return
      }
      var reg = /^[1][0-9][0-9]{9}$/
      if (!reg.test(this.form.cardmobile)) {
        Toast.info('请输入正确格式的手机号!')
        return false
      }
      var regEmail = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (this.form.email != '' && !regEmail.test(this.form.email)) {
        Toast.info('请输入正确格式的邮箱!')
        return false
      }
      let data = {
        cardempname: this.form.cardempname,
        cardsex: this.form.cardsex,
        cardmobile: this.form.cardmobile,
        wxnumber: this.form.wxnumber,
        workarea: this.form.workarea,
        pdesc: this.form.pdesc,
        carddegreeno: this.form.carddegreeno,
        email: this.form.email,
        cardstyle: this.form.cardstyle,
        headimg: this.form.headimg,
        photo: this.form.photo
      }
      updateCard(data).then(res => {
        Toast.succeed('编辑成功')
        this.$router.replace('/visitingcard')
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
@import './index.styl';
</style>
